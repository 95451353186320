import React, { Fragment } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import Hero from '@components/theme/Hero';
import Section from '@components/theme/Section';
import CtaButton from '@components/ui/CtaButton';
import { getFirstBlock } from '@lib/blocks';

const utmMedium = 'partners';

export default function PartnersTemplate({
  data: { page },
  pageContext: { language, refs, layouts, configuration }
}) {
  const {
    title,
    description,
    link,
    offers
  } = getFirstBlock(page);

  return (
    <Fragment>
      <Hero title={title} divider={{ variant: 4, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <Section>
        <div css={css`
          padding: 4rem 0;
        `}
        >
          <div className="container">
            <h1 className="text-center pb-2">
              {description}
            </h1>
            <CtaButton id="formlink" link={link} utmMedium={utmMedium} utmContent="cta_button_features" />
          </div>
        </div>
      </Section>
      <Section
        className='bg-primary'
        dividerTop={{ variant: 4 }}
      >
        <div className="container">
          <div className="row">
            {offers.map(({ entry }, i) => {
              return (
                <div className="d-flex col-md-6 mb-4" key={i}>
                  <div className="card card-body">
                    <div className="flex-grow-1">
                      <div className="h3">{entry.entryTitle}</div>
                      <div dangerouslySetInnerHTML={{ __html: entry.entryDescription }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <CtaButton id="formlink2" link={link} utmMedium={utmMedium} utmContent="cta_button_features" dark />
        </div>
      </Section>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($partnersId: String) {
    page: wpCustomPage(id: { eq: $partnersId }) {
      language {
        code
      }
      block {
        block {
          ... on WpCustomPage_Block_Block_PartnersSection {
            title
            description
            link {
              linkStyle
              linkText
              linkUrl
            }
            offers {
              entry {
                entryDescription
                entryTitle
              }
            }
          }
        }
      }
    }
  }
`;